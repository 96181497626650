import axios from "axios";
import { createStore } from "vuex";

const api = axios.create({
    baseURL: 'https://build-a-bot-api.herokuapp.com/api'
});

export const store = createStore({
    state: () => ({
        cart: [],
        parts: null
    }),
    mutations: {
        addRobotToCart(state, robot) {
            state.cart.push(robot);
        },

        removeRobotFromCart(state, id) {
            state.cart = state.cart.filter(robot => robot.id !== id);
        },

        clearCart(state) {
            state.cart = [];
        },

        updateParts(state, parts) {
            state.parts = parts;
        }
    },
    actions: {
        getParts(context) {
            api.get('/parts')
                .then(result => context.commit('updateParts', result.data));
        }
    },
    getters: {
        cartSaleItems(state) {
            return state.cart.filter(item => item.head.onSale);
        }
    }
});