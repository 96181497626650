<template>
    <div>
        <h1>404, Not Found</h1>
        
        <router-link to="/">Home</router-link>
    </div>
</template>

<script>
export default {
    name: 'NotFound'
}
</script>

<style scoped>
h1 {
    color: tomato;
}
</style>