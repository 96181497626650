import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../pages/HomePage.vue';
import RobotBuilder from '../pages/RobotBuilder.vue';
import PartInfo from '../pages/PartInfo.vue';
import BrowseParts from '../pages/BrowseParts.vue';
import BrowseHeads from '../pages/BrowseHeads.vue';
import BrowseArms from '../pages/BrowseArms.vue';
import BrowseBases from '../pages/BrowseBases.vue';
import BrowseTorsos from '../pages/BrowseTorsos.vue';
import NotFound from '../pages/NotFound.vue';
import Cart from '../pages/Cart.vue';
import { validatePartsParams } from './guards';

const routes = [
    { 
        path: '/', 
        name: 'Home', 
        component: HomePage 
    },
    { 
        path: '/builder', 
        name: 'Builder', 
        component: RobotBuilder 
    },
    {
        path: '/parts/browse',
        name: 'BrowseParts',
        component: BrowseParts,
        children: [
            {
                path: 'heads',
                name: 'BrowseHeads',
                component: BrowseHeads
            },
            {
                path: 'bases',
                name: 'BrowseBases',
                component: BrowseBases
            },
            {
                path: 'arms',
                name: 'BrowseArms',
                component: BrowseArms
            },
            {
                path: 'torsos',
                name: 'BrowseTorsos',
                component: BrowseTorsos
            },
        ]
    },
    { 
        path: '/parts/:partType/:id', 
        name: 'Parts', 
        component: PartInfo,
        beforeEnter: validatePartsParams
    },
    {
        path: '/cart',
        name: 'Cart',
        component: Cart
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
