<template>
    <div class="preview-content">
        <div class="top-row">
            <img :src="addBaseUrl(robot.head.src)" alt="Robot head preview">
        </div>
        <div class="middle-row">
            <img :src="addBaseUrl(robot.leftArm.src)" alt="Robot left arm preview" class="rotate-left">
            <img :src="addBaseUrl(robot.torso.src)" alt="Robot torso preview">
            <img :src="addBaseUrl(robot.rightArm.src)" alt="Robot right arm preview" class="rotate-right">
        </div>
        <div class="bottom-row">
            <img :src="addBaseUrl(robot.base.src)" alt="Robot base preview">
        </div>
    </div>
</template>

<script>

import { addBaseUrl} from '../helpers';

export default {
    props: ['robot'],
    name: 'RobotPreview',
    methods: {
        addBaseUrl
    }    
}
</script>

<style scoped>
.top-row {
  display: flex;
  justify-content: space-around;
}
.middle-row {
  display: flex;
  justify-content: center;
}
.bottom-row {
  display: flex;
  justify-content: space-around;
  border-top: none;
}

.preview-content {
    border: 1px solid #999;
}

.preview img {
    width: 50px;
    height: 50px;
}

.rotate-right {
    transform: rotate(90deg);
}

.rotate-left {
    transform: rotate(-90deg);
}
</style>