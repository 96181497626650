<template>
    <div>
        <div class="header" @click="open = !open">
            <span v-if="open">&#x25B2; Collapse</span>
            <span v-else>&#x25BC; Expand</span>
        </div>
        <slot v-if="open">
            <div>Default Content</div>
        </slot>
    </div>
</template>

<script>
export default {
    name: 'CollapsibleSection',
    data: () => ({
        open: true
    })
}
</script>

<style scoped>
    .header {
        background-color: #bbb;
        padding: 10px;
        cursor: pointer;
        border: 1px solid #999;
        margin-bottom: -1px;
    }
</style>