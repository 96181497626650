<template>
    <div>
        <h1>Cart</h1>
        <button @click="clearCart">Clear</button>
        <table>
            <thead>
                <tr>
                    <th>Preview</th>
                    <th>Robot</th>
                    <th class="cost">Cost</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(robot, index) in cart" :key="index">
                    <td class="preview">
                        <RobotPreview :robot="robot" />
                    </td>
                    <td>{{ robot.head.title }}</td>
                    <td class="cost">{{ robot.cost }}</td>
                    <td><button @click="removeItem(robot.id)">Delete</button></td>
                </tr>
            </tbody>
        </table>

        <h1>Sale Items</h1>
        <table>
            <thead>
                <tr>
                    <th>Robot</th>
                    <th class="cost">Cost</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(robot, index) in cartSaleItems" :key="index">
                    <td>{{ robot.head.title }}</td>
                    <td class="cost">{{ robot.cost }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import RobotPreview from '../components/RobotPreview.vue';

export default {
  name: 'Cart',
  components: {RobotPreview},
  computed: {
      cart() {
          return this.$store.state.cart;
      },

      cartSaleItems() {
          return this.$store.getters.cartSaleItems;
      }
  },
  methods: {
      clearCart() {
          if (confirm('Clear cart?')) {
              this.$store.commit('clearCart');
              this.$router.push('/builder');
          }
      },

      removeItem(id) {
          if (confirm('Remove item?')) {
              this.$store.commit('removeRobotFromCart', id);
          }
      }
  }
}
</script>

<style scoped>
td, th {
    text-align: left;
    padding: 5px;
    padding-right: 20px;
}
.cost {
    text-align: right;
}
</style>