<template>
  <div class="part" :class="position">
    <router-link 
      :to="{ 
        name: 'Parts', 
        params: { 
            id: this.selectedPart.id, partType: 
            this.selectedPart.type 
          }
        }">
      <img :src="addBaseUrl(selectedPart.src)" :title="title">
    </router-link>
    <button class="prev-selector" @click="selectPreviousPart"></button>
    <button class="next-selector" @click="selectNextPart"></button>
    <span v-show="selectedPart.onSale" class="sale">Sale!</span>
  </div>
</template>

<script>
import { addBaseUrl} from '../helpers';

export default {
    props: {
        parts: {
            type: Array,
            required: true
        },
        position: {
            type: String,
            required: true,
            validator(value) {
                return ['left', 'right', 'top', 'bottom', 'center'].includes(value)
            }
        },
        title: {
            type: String
        }
    },
    data: () => ({
        selectedPartIndex: 0
    }),
    computed: {
        selectedPart() {
            return this.parts[this.selectedPartIndex];
        }
    },
    created() {
        this.emitSelectedPart();
    },
    updated() {
        this.emitSelectedPart();
    },
    methods: {
        addBaseUrl,
        emitSelectedPart() {
            this.$emit('partSelected', this.selectedPart);
        },

        selectNextPart() {
            this.selectedPartIndex = ++this.selectedPartIndex % this.parts.length;
        },

        selectPreviousPart() {
            const deprecatedIndex = this.selectedPartIndex - 1;
            this.selectedPartIndex = deprecatedIndex < 0
                ? this.parts.length - 1
                : deprecatedIndex
        }
    }
}
</script>

<style scoped>
.part {
  position: relative;
  width: 165px;
  height: 165px;
  border: 3px solid #999;
}
.sale {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: white;
  background-color: red;
  padding: 3px;
}
.part-title {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: red;
  padding-top: 5px;
  top: -25px;
}
.part img {
  width: 165px;
}
.top {
  border-bottom: none;
}
.left {
  border-right: none;
}
.right {
  border-left: none;
}
.bottom {
  border-top: none;
}
.left img {
  transform: rotate(-90deg);
}
.right img {
  transform: rotate(90deg);
}
.prev-selector {
  position: absolute;
  z-index: 1;
  top: -3px;
  left: -28px;
  width: 25px;
  height: 171px;
}
.next-selector {
  position: absolute;
  z-index: 1;
  top: -3px;
  right: -28px;
  width: 25px;
  height: 171px;
}
.left .prev-selector:after,
.right .prev-selector:after {
  content: "\25B2";
}
.left .next-selector:after,
.right .next-selector:after {
  content: "\25BC";
}
.top .prev-selector:after,
.bottom .prev-selector:after,
.center .prev-selector:after {
  content: "\25C4";
}
.top .next-selector:after,
.bottom .next-selector:after,
.center .next-selector:after {
  content: "\25BA";
}
.center .prev-selector,
.center .next-selector {
  opacity: 0.8;
}
.left .prev-selector {
  top: -28px;
  left: -3px;
  width: 144px;
  height: 25px;
}
.left .next-selector {
  top: auto;
  bottom: -28px;
  left: -3px;
  width: 144px;
  height: 25px;
}
.right .prev-selector {
  top: -28px;
  left: 24px;
  width: 144px;
  height: 25px;
}
.right .next-selector {
  top: auto;
  bottom: -28px;
  left: 24px;
  width: 144px;
  height: 25px;
}
.highlight {
  border: 1px solid red;
}
</style>