<template>
    <div class="home">
        <div>
            <img src="../assets/robot-home.png" class="robot">
        </div>    
        <div class="get-started">
            <router-link to="/builder">Get started</router-link> building your first robot!
        </div>
    </div>    
</template>

<script>
export default {
    name: 'HomePage',
}
</script>

<style scoped>
.home {
    text-align: center;
}

.robot {
    height: 300px;
}

.get-started {
    padding-top: 20px;
    font-size: 25px;
}
</style>