<template>
    <h1>Arms</h1>

    <div class="grid">
        <router-link
            v-for="part in parts" 
            :key="part.id" 
            :to="{ 
                name: 'Parts', 
                params: { 
                    id: part.id,
                    partType: part.type 
                }
            }">
        <img :src="addBaseUrl(part.src)" :alt="part.name" />
        </router-link>
    </div>
</template>

<script>
import { addBaseUrl } from '../helpers';
export default {
    name: 'BrowseArms',
    methods: {
        addBaseUrl
    },
    computed: {
        parts() {
            if (this.$store.state.parts) {
                return this.$store.state.parts.arms
            }
            return [];
        }
    }
}
</script>

<style scoped>
    .grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 40px;
    }

    .grid img {
        width: 200px;
    }
</style>