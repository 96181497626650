<template>
    <div class="content" v-if="availableParts">
        <div class="preview">
            <CollapsibleSection>
                <RobotPreview :robot="selectedRobot" />
            </CollapsibleSection>
            <button class="add-to-cart" @click="addToCart">Add to Cart</button>
        </div>

        <div class="top-row">
            <PartSelector 
                :parts="availableParts.heads" 
                position="top" 
                title="head" 
                @partSelected="part => this.selectedRobot.head = part" />
        </div>
        <div class="middle-row">
            <PartSelector 
                :parts="availableParts.arms" 
                position="left" 
                title="arm" 
                @partSelected="part => this.selectedRobot.leftArm = part" />
            <PartSelector 
                :parts="availableParts.torsos" 
                position="center" 
                title="torso" 
                @partSelected="part => this.selectedRobot.torso = part" />
            <PartSelector 
                :parts="availableParts.arms" 
                position="right" 
                title="arm" 
                @partSelected="part => this.selectedRobot.rightArm = part"/>
        </div>
        <div class="bottom-row">
            <PartSelector 
                :parts="availableParts.bases" 
                position="bottom" 
                title="base" 
                @partSelected="part => this.selectedRobot.base = part"/>
        </div>
    </div>    
</template>

<script>
import PartSelector from '../components/PartSelector.vue';
import RobotPreview from '../components/RobotPreview.vue';
import CollapsibleSection from '../components/CollapsibleSection.vue';
import { addBaseUrl} from '../helpers';
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'RobotBuilder',
    components: { PartSelector, CollapsibleSection, RobotPreview },
    beforeRouteLeave(to, from, next) {
        if (this.addedToCart) {
            next(true);
        } else {
            const response = confirm('You have not added your robot to cart, are you sure you want to leave?');
            next(response);
        }
    },
    data() {
        return {
            selectedRobot: {
                head: {},
                leftArm: {},
                torso: {},
                rightArm: {},
                base: {}
            },
            addedToCart: false
        }
    },
    created() {
        this.$store.dispatch('getParts');
    },
    computed: {
        availableParts() {
            return this.$store.state.parts;
            // if (this.$store.state.parts) {
            // }
            // return { heads: [], arms: [], torsos: [], bases: [] };
        }
    },
    methods: {
        addBaseUrl,
        addToCart() {
            const robot = this.selectedRobot;
            const cost = robot.head.cost + robot.leftArm.cost + robot.torso.cost + robot.rightArm.cost + robot.base.cost;
            const id = uuidv4();

            this.$store.commit('addRobotToCart', { id, ...robot, cost });
            this.addedToCart = true;
        }
    }
}
</script>

<style scoped>
.top-row {
  display: flex;
  justify-content: space-around;
}
.middle-row {
  display: flex;
  justify-content: center;
}
.bottom-row {
  display: flex;
  justify-content: space-around;
  border-top: none;
}

.content {
    position: relative;
}

.preview {
    position: absolute;
    top: -20px;
    right: 0;
    width: 210px;
    height: 210px;
    padding: 5px;
}

.add-to-cart {
    width: 210px;
    padding: 3px;
    font-size: 16px;
    background: tomato;
    color: white;
    padding: 10px;
    border: 1px solid #999;
    margin-top: -1px;
    cursor: pointer;
    font-weight: bold;
}
</style>