import {store} from '../store';

export function validatePartsParams(to, from, next) {
    const parts = store.state.parts;

    if (!parts) {
        store.dispatch('getParts');
        next(true);
    }

    const isValidId = Number.isInteger(Number(to.params.id));
    const isValidType = Object.keys(parts).includes(to.params.partType);
    
    if (!isValidId) {
        alert('Invalid ID!');
    }

    if (!isValidType) {
        alert('Invalid part type!');
    }

    next(isValidId && isValidType);
}