<template>
    <div class="container" v-if="part">
        <div>
            <h1>{{ part.title }}</h1>
            <div>{{ part.description }}</div>
        </div>
        <img :src="addBaseUrl(part.src)" alt="Part image">
    </div>
</template>

<script>
import {addBaseUrl} from '../helpers';

export default {
    name: 'PartInfo',
    created() {
        this.$store.dispatch('getParts');
    },
    computed: {
        part() {
            const parts = this.$store.state.parts;
            if (!parts) {
                return null;
            }
            
            const { partType, id } = this.$route.params;
            return parts[partType].find(part => part.id == id);
        }
    },
    methods: { addBaseUrl }
}
</script>

<style scoped>
    .container {
        display: flex;
        justify-content: space-between;
    }
</style>
