<template>
  <div id="app">
    <header>
      <nav>
        <ul>
          <li class="nav-item">
            <img class="logo" src="./assets/build-a-bot-logo.png">
            Build-a-Bot
          </li>
          <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'Home' }">Home</router-link>
          </li>
          <li class="nav-item">
              <router-link active-class="foo" class="nav-link" :to="{ name: 'Builder' }">Builder</router-link>
          </li>
          <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'BrowseParts' }">Browse Parts</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'Cart' }">Cart</router-link>
            <div class="cart-items">{{ cart.length }}</div>
          </li>
        </ul>
      </nav>
    </header>
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>

export default {
  name: 'App',
  computed: {
    cart() {
      return this.$store.state.cart;
    }
  }
}
</script>

<style>
body {
  background: linear-gradient(to bottom, #555, #999);
  background-attachment: fixed;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

main {
  margin: 0 auto;
  padding: 30px;
  background-color: white;
  width: 1024px;
  min-height: 300px;
}

header {
  background-color: #999;
  width: 1084px;
  margin: 0 auto;
}

ul {
  padding: 3px;
  display: flex;
}

.nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  font-size: 22px;
  border-right: 1px solid #bbb;
  position: relative;
}

.nav-link {
  text-decoration: none;
  color: inherit;
}

.foo {
  color: red;
}

.logo {
  vertical-align: middle;
  height: 30px;
}

.router-link-active {
  color: #fff;
}

button {
  background: none;
  border: none;
  padding: 5px;
  transition: 0.3s all;
}
button:hover {
  background: #bbb;
}

.cart-items {
  position: absolute;
  font-size: 12px;
  width: 18px;
  height: 18px;
  background: tomato;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: 2px;
  right: -2px;
  font-weight: bold;
  color: white;
}

</style>
